<script
    lang="ts"
    setup
>
    import generateGoogleAuthUrl from '~/helpers/generateGoogleAuthUrl'

    type Props = {
        registration?: boolean
    }

    const props = defineProps<Props>()

    const googleAuth = (): void => {
        // Встановлюється в процесі привʼязки сервісу в Налаштуваннях
        useAppCookie('oauth-service-binding-process').value = null

        location.href = generateGoogleAuthUrl()
    }
</script>

<template>
    <AppButton
        class="w-full"
        content-class="gap-2"
        @click="googleAuth()"
    >
        <AppIconGoogle
            size="18"
            color="#000"
        />

        {{ $t(`sign-${ props.registration ? 'up' : 'in' }-with-google`) }}
    </AppButton>
</template>
