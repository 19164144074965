<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                d="M22.56 12.25C22.56 11.47 22.49 10.72 22.36 10H12V14.26H17.92C17.66 15.63 16.88 16.79 15.71 17.57V20.34H19.28C21.36 18.42 22.56 15.6 22.56 12.25Z"
                :fill="color"
            />

            <path
                d="M12.0001 23.0001C14.9701 23.0001 17.4601 22.0201 19.2801 20.3401L15.7101 17.5701C14.7301 18.2301 13.4801 18.6301 12.0001 18.6301C9.14005 18.6301 6.71005 16.7001 5.84005 14.1001H2.18005V16.9401C3.99005 20.5301 7.70005 23.0001 12.0001 23.0001Z"
                :fill="color"
            />

            <path
                d="M5.84 14.0901C5.62 13.4301 5.49 12.7301 5.49 12.0001C5.49 11.2701 5.62 10.5701 5.84 9.91007V7.07007H2.18C1.43 8.55007 1 10.2201 1 12.0001C1 13.7801 1.43 15.4501 2.18 16.9301L5.03 14.7101L5.84 14.0901Z"
                :fill="color"
            />

            <path
                d="M12.0001 5.38C13.6201 5.38 15.0601 5.94 16.2101 7.02L19.3601 3.87C17.4501 2.09 14.9701 1 12.0001 1C7.70005 1 3.99005 3.47 2.18005 7.07L5.84005 9.91C6.71005 7.31 9.14005 5.38 12.0001 5.38Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
